body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  text-align: left;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App-logo {
  height: 80px;
}

.App-header {
  /* padding: 0px; */
  color: white;
  text-align: left; 
  /* height: 126px; */
}

.App-header .title{
  margin-top: 40px;
}

.App-header a{
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  transition: .2s all;
}
.App-header a:hover{
  opacity: .7;
}
.App-name {
  display: inline-block;
   vertical-align: middle;
}
.rec-dot{
  background-color: rgb(38, 174, 253);
  box-shadow: 0 0 1px 3px rgba(164, 223, 247, 0.5);
}

.rec-dot:hover, button.rec-dot:active, button.rec-dot:focus  {
  box-shadow: 0 0 1px 3px rgba(16, 147, 235, 0.5);
}
.rec.rec-arrow-left:hover:enabled,
.rec.rec-arrow-right:hover:enabled{
    background-color: rgb(38, 174, 253);
    box-shadow: 0 0 1px 3px rgba(164, 223, 247, 0.5);
}
#spinning-circle {
  animation-name: spinning-circle;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  width: 40px;
  height: 40px;
}

@-webkit-keyframes spinning-circle {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 125% 125%;
    transform-origin: 125% 125%;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: 125% 125%;
    transform-origin: 125% 125%;
  }
}